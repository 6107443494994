@import "../../index.css";

.dont-show .ant-select-selection-item {
  display: none !important;
}

.mauritius-small-size {
  height: 20px;
  width: 15px;
}

.confident-blue-color {
  color: var(--confidentBlue);
}

.right-align-text {
  text-align: right;
}

.portfolio-selection {
  width: 50% !important;
  float: right;
}

.add-top-margin {
  margin-top: 10px;
}

.selected-asset-class-table {
  background-color: var(--confidentGreen) !important;
}

.break-word {
  /* word-break: break-all; */
  max-width: 60vw !important;
}

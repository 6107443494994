@import "../../index.css";

.confident-logo-top-menu {
  margin-top: 1.5%;
  padding-bottom: 1.5%;
  max-height: 100% !important;
  height: 50px;
  width: 150px;
}

.logo-side-menu {
  margin-top: 1.5%;
  max-height: 85px !important;
  height: 85px;
  padding: 5%;
}

.screen-container {
  display: flex;
  height: max-content !important;
  margin-top: 0px !important;
  width: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .logo-top-menu {
    padding-bottom: 4px;
    max-height: 100% !important;
    height: 25px;
    width: 25px;
    /* margin-right: 15px !important; */
  }
  .screen-container {
    display: flex;
    height: max-content !important;
    margin-top: 0 !important;
    width: 100% !important;
    min-width: 100% !important;
  }

  .cssloader {
    padding-top: calc(45vh - 25px);
    z-index: 1;
    position: absolute;
    left: 0 !important;
    right: 0 !important;
    margin-left: auto !important;
    margin-right: auto !important;
    display: none;
    background-color: rgba(150, 148, 141, 0.5);
    height: 100%;
  }
}

body {
  background-color: white !important;
}

.sidebar-style {
  display: inline-block;
  margin-right: 10px;
  margin-top: -25px;
  min-width: 20vw;
  max-width: 100px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-height: 100vh;
  flex: 1 1 auto;
  /* padding-bottom: 20%; */
  /*height: calc(100vh - 192px); */
}

.MuiPaper-elevation4 {
  box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.35) !important;
}

.submenu-menu-item {
  height: 35px !important;
}

.username-submenu-menu-item {
  cursor: context-menu !important;
}

.side-droplist {
  /* margin-bottom: 5%; */
  padding: 1%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  width: 80%;
}

.side-item-droplist {
  margin-bottom: 5%;
  padding: 1%;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  width: 100%;
}

.special-front-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 8% !important;
}

.side-name-droplist {
  font-size: 1.2em;
  margin-top: 3.6%;
}

.align-items-vertical-center {
  display: flex;
  align-items: center;
}

.droplist-icon-col {
  /* width: auto !important; */
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 15% !important;
}

.chev-icon {
  float: right;
  font-size: 1.5em;
  font-weight: none !important;
  margin-left: 60%;
}

.chev-icon-col {
  /* margin-top: 3%; */
  cursor: pointer;
}

.home-droplist-divider {
  background-color: #f0f0ed !important;
}

.component-card {
  box-shadow: 5px 10px 8px #888888;
  border-radius: 25px !important;
  border: 1px solid;
  /* width: 500px !important; */
  padding: 10px;
  width: 50%;
  margin: 0 auto;
}

.icon-col {
  /* font-size: 22px !important; */
  color: var(--confidentGreen) !important;
}

.component-background {
  background-image: url("../../images/ConfidentLoginBackground.svg");
  background-size: cover;
  height: 85vh;
  overflow-x: hidden;
}

.main-router {
  z-index: 0;
  position: relative;
}

.cssloader {
  padding-top: calc(45vh - 25px);
  z-index: 1;
  position: absolute;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  display: none;
  background-color: rgba(150, 148, 141, 0.5);
  height: 100%;
  /* transform: translate(-50%); */
}

.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: var(--confidentBlue) transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent transparent var(--confidentGreen) transparent;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }

  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: var(--confidentBlue);
  font-family: Seravek;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  letter-spacing: 10px;
}

.icon-row {
  color: var(--confidentGreen) !important;
  height: 100%;
  width: 100% !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  /* background-color: var(--confidentGrey); */
}

.icon-label {
  font-size: 12px;
}

.no-padding {
  padding: 0px !important;
}

.active-page {
  color: rgba(0, 71, 90, 0.8) !important;

  /* color: #fff !important; */
}

.active-page-drawer {
  background-color: rgba(0, 71, 90, 0.8) !important;
  color: #fff !important;
}

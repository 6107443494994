@import "../../index.css";

.error-message {
  color: red;
}

.center-items {
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.add-top-margin {
  margin-top: 20px !important;
}

.add-small-top-margin {
  margin-top: 10px !important;
}

.info-link {
  color: #1890ff;
  /* text-decoration: underline; */
}

.logo-login {
  margin-top: 20px !important;
  padding-bottom: 1.5%;
  max-height: 100% !important;
  height: 100px;
}

@media only screen and (max-width: 1200px) {
  .logo-login {
    margin-top: 20px;
    padding-bottom: 1.5%;
    max-height: 100% !important;
    height: 60px;
  }
  .full-height {
    height: calc(100vh - 200px);
  }
}

.full-height {
  height: calc(100vh - 300px);
}

.login-card {
  box-shadow: 5px 10px 8px #888888;
  border-radius: 25px !important;
  border: 1px solid;
  width: 350px !important;
}

.login-background {
  background-image: url("../../images/ConfidentLoginBackground.svg");
  background-size: cover;
  height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.login-button {
  background-color: var(--confidentGreen) !important;
  color: #fff !important;
}

.logout-button {
  background-color: red !important;
  color: #fff !important;
}

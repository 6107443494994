body {
  margin: 0;
  font-family: Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Helvetica";
  src: local("Helvetica_Neue_LT_45_Light"),
    url(./fonts/Helvetica_Neue_LT_45_Light.ttf) format("truetype");
}

@font-face {
  font-family: "Seravek";
  src: local("Seravek"), url(./fonts/Seravek.ttc) format("truetype");
}

:root {
  --confidentBlue: #00475a;
  --confidentGreen: #91c840;
  --confidentGrey: #6d6e71;
  --confidentLightBlue: #097896;
}

.paragraph-font {
  font-family: Helvetica;
}

.heading-font {
  font-family: Seravek;
}

.bottom-section {
  position: fixed;
  bottom: 0;
}

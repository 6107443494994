@import "../../index.css";

.news-feed-item {
  color: white;
}
.news-feed-item-width {
  /* width: 25% !important; */
  margin-left: 0% !important;
  margin-right: 0% !important;
  margin-top: 10px !important;
}

@media only screen and (max-width: 1200px) {
  .news-feed-item-width {
    width: 100% !important;
  }
  .news-feed-time {
    font-size: 10px !important;
    text-align: right;
  }
}

.news-feed-heading {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 20px;
  background-color: rgb(84, 131, 144);
  /* opacity: 90% !important; */
}

.news-feed-text {
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgba(0, 71, 90, 0.9);
  /* opacity: 80% !important; */
  font-size: 15px;
}

.urgent-notification {
  color: red;
}

.general-notification {
  color: var(--confidentGreen);
}

.warning-notification {
  color: orange;
}

.heading {
  font-size: 25px;
  color: var(--confidentBlue);
  text-align: left;
  font-weight: bold;
}

.news-feed-time {
  font-size: 15px;
  text-align: right;
}

.pointer-mouse {
  cursor: pointer;
}

.right-align-text {
  text-align: right !important;
}

.add-margin-right {
  margin-right: 10px;
}

.add-padding-inside {
  padding: 5px !important;
}

.error {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
}

.error-img {
  width: 100%;
  height: 100%;
  padding-left: 1.5%;
}

.error-msg-font {
  font-family: errorFont;
  font-weight: 700;
}

.error-msg1 {
  font-size: 200%;
  text-align: center;
}

.error-msg2 {
  font-size: 150%;
  text-align: center;
}

.error-msg {
  align-items: top;
}

@import "../../index.css";

.icon-size {
  font-size: 30px;
  color: var(--confidentBlue) !important;
}

.add-top-margin {
  margin-top: 10px;
}
